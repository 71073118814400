import '../sass/project.scss';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-date-fns';
import Tooltip from 'bootstrap/js/dist/tooltip';

Chart.register(ChartDataLabels);

/* FUNCTIONS */
function initializeForm(columnNames, class1Choices, class2Choices) {
  // Function to update fields based on the type selection
  function updateFieldOptions(formPrefix) {
    const typeSelect = document.querySelector(
      `select[name="${formPrefix}-type"]`,
    );
    const setSelect = document.querySelector(
      `select[name="${formPrefix}-set"]`,
    );
    const positionSelect = document.querySelector(
      `select[name="${formPrefix}-position"]`,
    );

    if (!setSelect || !positionSelect) {
      console.error('Set or Position select box not found');
      return;
    }

    setSelect.innerHTML = '';
    positionSelect.innerHTML = '';

    if (typeSelect && typeSelect.value === 'Assets') {
      setSelect.add(new Option('Asset Class', 'Asset Class'));
      columnNames.forEach((name) => {
        positionSelect.add(new Option(name, name));
      });
    } else if (typeSelect && typeSelect.value === 'Classes') {
      setSelect.add(new Option('Class 1', 'Class 1'));
      setSelect.add(new Option('Class 2', 'Class 2'));

      setSelect.addEventListener('change', function () {
        positionSelect.innerHTML = ''; // Clear current options
        const choices =
          this.value === 'Class 1' ? class1Choices : class2Choices;
        choices.forEach(([value, name]) => {
          positionSelect.add(new Option(name, value));
        });
      });
    }
  }

  // Attach event listeners to all existing 'type' select elements
  document.querySelectorAll('select[name$="-type"]').forEach((select) => {
    select.addEventListener('change', function () {
      const formPrefix = this.name.match(/form-(\d+)-type/)[1];
      updateFieldOptions(`form-${formPrefix}`);
    });
  });

  // Handler for adding more forms
  document.getElementById('add_more_constraints').addEventListener('click', function () {
    var totalForms = document.getElementById('id_form-TOTAL_FORMS');
    var formNum = parseInt(totalForms.value);
    var newForm = document.querySelector('.form-row').cloneNode(true);
    newForm.innerHTML = newForm.innerHTML.replace(
      /form-(\d)-/g,
      'form-' + formNum + '-',
    );

    // Remove labels from the cloned form
    Array.from(newForm.querySelectorAll('label')).forEach((label) =>
      label.remove(),
    );

    document.getElementById('formset_container').appendChild(newForm);
    totalForms.value = formNum + 1;

    // Attach event listeners to new form elements
    const newTypeSelect = newForm.querySelector(
      `select[name="form-${formNum}-type"]`,
    );
    if (newTypeSelect) {
      newTypeSelect.addEventListener('change', function () {
        updateFieldOptions(`form-${formNum}`);
      });
    }
  });

  // Initial update for the first form to set up default selections
  updateFieldOptions('form-0');
}

function subscribeToTaskUpdates(taskId) {
  // Show the task status elements
  document.getElementById('task-status').style.display = 'block';
  document.getElementById('progressStatus').style.display = 'block';
  document.querySelector('.progress').style.display = 'block';

  // Create a new WebSocket for task updates
  const ws_scheme = window.location.protocol === 'https:' ? 'wss' : 'ws';

  // Determine the appropriate WebSocket port based on the environment
  let ws_port = '';
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    // Use port 8000 for development environment
    ws_port = ':8000';
  }

  const ws_path =
    ws_scheme +
    '://' +
    window.location.hostname +
    ws_port +
    '/ws/progress_updates/' +
    taskId +
    '/';
  const ws = new WebSocket(ws_path);

  ws.onopen = function () {
    const progressStatus = document.getElementById('progressStatus');
    if (progressStatus) {
      progressStatus.textContent = 'Connected. Waiting for updates...';
    }
  };

  ws.onmessage = function (e) {
    const data = JSON.parse(e.data);
    const progressBar = document.getElementById('progressBar');
    const progressStatus = document.getElementById('progressStatus');

    if (progressBar && data.progress) {
      progressBar.style.width = data.progress + '%';
      progressBar.setAttribute('aria-valuenow', data.progress);
      progressBar.textContent = data.progress + '%';
    }

    if (progressStatus && data.message) {
      progressStatus.textContent = data.message;
    }
  };

  ws.onclose = function (e) {
    console.log('WebSocket closed with code:', e.code, 'reason:', e.reason);
    const progressStatus = document.getElementById('progressStatus');
    if (progressStatus) {
      progressStatus.textContent = 'Task completed or connection closed.';
    }
  };

  ws.onerror = function (e) {
    console.log('WebSocket error:', e);
    const progressStatus = document.getElementById('progressStatus');
    if (progressStatus) {
      progressStatus.textContent = 'WebSocket connection error.';
    }
  };
}

function fetchAndPopulateSelectField(fieldName, clientId, strategyLevel, csrftoken) {
  const url = `/prasys_app/get-portfolios/?client_id=${clientId}&field_name=${fieldName}&strategy_level=${strategyLevel}`;
  const selectElement = document.querySelector(`select[name="${fieldName}"]`);

  // Clear existing options before fetching new ones
  selectElement.innerHTML = '';

  // Add a "no selection" option for optional fields like 'relative'
  if (fieldName === 'relative') {
    const placeholderOption = new Option("--------", "");
    selectElement.add(placeholderOption);
  }

  fetch(url, {
    headers: { 'X-CSRFToken': csrftoken },
  })
  .then(response => response.json())
  .then(data => {
    // Populate the select with fetched options
    data.forEach(item => {
      const option = new Option(item.name, item.id);
      selectElement.add(option);
    });
  })
  .catch(error => console.error(`Error fetching ${fieldName}:`, error));
}

function onClientOrLevelChange(clientSelect, levelSelect, csrftoken) {
  const clientId = clientSelect.value;
  const strategyLevel = levelSelect.value;
  ['portfolio_name', 'benchmark', 'target', 'relative'].forEach(fieldName => {
    fetchAndPopulateSelectField(fieldName, clientId, strategyLevel, csrftoken);
  });
}

function toggleFieldsBasedOnAnalysis(analysisSelect, periodDiv, annualisedDiv, investmentlevelDiv, portfolioDiv, relativeDiv) {
  const analysisType = analysisSelect.value;

  // Hide fields based on selection
  if (analysisType === 'calendar_returns' || analysisType === 'period_returns') {
    periodDiv.style.display = 'none';
    annualisedDiv.style.display = 'none';
    investmentlevelDiv.style.display = 'flex';
    portfolioDiv.style.display = 'flex';
    relativeDiv.style.display = 'flex';
  } else if (analysisType === 'confidence_intervals' || analysisType === 'scatter_plot') {
    periodDiv.style.display = 'flex';
    annualisedDiv.style.display = 'none';
    investmentlevelDiv.style.display = 'flex';
    portfolioDiv.style.display = 'flex';
    relativeDiv.style.display = 'none';
  } else if (analysisType === 'std_dev') {
    periodDiv.style.display = 'flex';
    annualisedDiv.style.display = 'flex';
    investmentlevelDiv.style.display = 'flex';
    portfolioDiv.style.display = 'flex';
    relativeDiv.style.display = 'none';
  } else if (analysisType === 'returns') {
    periodDiv.style.display = 'flex';
    annualisedDiv.style.display = 'flex';
    investmentlevelDiv.style.display = 'flex';
    portfolioDiv.style.display = 'flex';
    relativeDiv.style.display = 'flex';
  } else {
    // Show fields for other types of analysisType
    periodDiv.style.display = 'flex';
    annualisedDiv.style.display = 'flex';
    investmentlevelDiv.style.display = 'flex';
    portfolioDiv.style.display = 'flex';
    relativeDiv.style.display = 'flex';
  }
}

function toggleFieldsBasedOnPeriod(periodInput, annualisedDiv, analysisSelect) {
  const analysisType = analysisSelect.value;
  const period = periodInput.value;

  // Hide fields based on selection if the analysisType is 'returns' or 'std_dev'
  if ((analysisType === 'returns' || analysisType === 'std_dev') && (period === '3' || period === '1000')) {
    annualisedDiv.style.display = 'none';
  } else if (analysisType === 'returns' || analysisType === 'std_dev') {
    // Show fields for 'returns' or 'std_dev' analysisType
    annualisedDiv.style.display = 'flex';
  }
}

function removeUnwantedPeriodOption(periodInput) {
  const unwantedOption = periodInput.querySelector('option[value="1000"]');
  if (unwantedOption) unwantedOption.remove();
}

function checkTaskStatus(taskId) {
  // Define URLs for both containers
  let statusCheckURL = `/data/check-task-status/${taskId}/`;

  // Function to handle status check, redirection, and error handling
  const handleTaskStatus = () => {
    fetch(statusCheckURL)
      .then((response) => response.json())
      .then((data) => {
        switch (data.state) {
          case 'SUCCESS':
            fetchAnalysisResults();

            // Remove existing event listener by cloning the download button
            var oldDownloadButton = document.getElementById('downloadChart');
            var newDownloadButton = oldDownloadButton.cloneNode(true);
            oldDownloadButton.parentNode.replaceChild(
              newDownloadButton,
              oldDownloadButton,
            );

            // Now add the event listener to the new download button
            newDownloadButton.addEventListener('click', function () {
              downloadChart('analysisChart');
            });
            clearInterval(pollInterval);
            break;
          case 'FAILURE':
            document.getElementById('progressStatus').textContent =
              `Error: ${data.details.error || 'Task failed with an error.'}`;
            clearInterval(pollInterval);
            break;
          default:
            console.info(`Task status: ${data.state}.`);
        }
      })
      .catch((error) => {
        console.error('Error fetching task status:', error);
        document.getElementById('progressStatus').textContent =
          'Error checking task status.';
        clearInterval(pollInterval);
      });
  };

  // Check task status initially and every 5 seconds
  handleTaskStatus();
  const pollInterval = setInterval(handleTaskStatus, 2000);
}

function transformTitle(analysisMethod, period, annualised, relative_name, lastDate) {
  // Map analysis methods to their titles
  const methodTitles = {
    'calendar_returns': 'Calendar Returns',
    'relative_calendar_returns': 'Relative Calendar Returns',
    'returns': 'Rolling Returns',
    'relative_returns': 'Relative Rolling Returns',
    'period_returns': 'Period Returns',
    'relative_period_returns': 'Relative Period Returns',
    'std_dev': 'Standard Deviation',
    'confidence_intervals': 'Confidence Intervals',
    'scatter_plot': 'Risk Return Scatter Plot',
  };

  // Use the mapping or default to the analysis method itself
  const titleMethod = methodTitles[analysisMethod] || analysisMethod;

  // Map period codes to readable formats
  const periodMapping = {
    '3': 'Quarterly',
    '1000': 'Year To Date',
    '12': '1 Year',
    '36': '3 Year',
    '60': '5 Year',
    '84': '7 Year',
    '120': '10 Year',
    '10000': 'Since Inception',
  };

  // Use the mapping or default to the period code itself
  const titlePeriod = periodMapping[period.toString()] || period;

  // Determine the annualisation status
  const titleAnnualised = annualised === 'Annualised' ? 'Annualised' : 'Cumulative';

  // Construct and return the final title
  if (['calendar_returns', 'relative_calendar_returns', 'period_returns', 'relative_period_returns'].includes(analysisMethod)) {
    return relative_name ? `${titleMethod} (${relative_name}, ${lastDate})` : `${titleMethod} (${lastDate})`;
  } else {
    return relative_name ? `${titleMethod} (${titlePeriod}, ${titleAnnualised}, ${relative_name}, ${lastDate})`.trim() : `${titleMethod} (${titlePeriod}, ${titleAnnualised}, ${lastDate})`.trim();
  }
}

function processLineChartData(data, roles, styleConfig) {
  const labels = data.index.map((dateStr) => new Date(dateStr));
  const distinctColors = ['#36a2eb', '#ff6384', '#ffce56', '#9966ff', '#4bc0c0', '#ff9f40', '#9b59b6', '#e67e22', '#2ecc71', '#e74c3c', '#f39c12', '#3498db'];
  let colorIndex = 0;

  const datasets = data.columns.map((column, i) => {
    const role = roles[column] || 'default';
    const borderColor = distinctColors[colorIndex % distinctColors.length];
    colorIndex++;

    return {
      label: column,
      data: labels.map((label, index) => ({
        x: label,
        y: data.data[index][i] * 100,
      })),
      fill: false,
      borderColor,
      borderWidth: styleConfig[role].borderWidth,
      borderDash: styleConfig[role].borderDash,
      pointHoverBorderColor: borderColor,
      pointHoverBorderWidth: styleConfig[role].pointHoverBorderWidth,
      tension: 0.1,
      pointHitRadius: 10,
      pointRadius: 0, // Points are invisible by default
      pointHoverRadius: 7, // Points become visible and larger when hovered over
    };
  });

  return { labels, datasets };
}

function processBarChartData(data, styleConfig) {
  const labels = data.year;
  let colorIndex = 0;
  const distinctColors = ['#36a2eb', '#ff6384', '#ffce56', '#9966ff', '#4bc0c0', '#ff9f40', '#9b59b6', '#e67e22', '#2ecc71', '#e74c3c', '#f39c12', '#3498db'];

  const datasets = [];

  // Use a loop to create datasets for each portfolio
  Object.keys(data).forEach((key) => {
    if (key !== 'year') { // Exclude 'year' key from the datasets
      const borderColor = distinctColors[colorIndex++ % distinctColors.length];
      datasets.push({
        label: key,
        data: data[key].map(value => parseFloat(value) * 100), // Convert string percentages to numbers and multiply by 100 for percentage format
        borderColor,
        borderWidth: styleConfig.default.borderWidth,
        backgroundColor: borderColor, // Use borderColor for backgroundColor in bar chart
      });
    }
  });

  return { labels, datasets };
}

function processPeriodicChartData(data, styleConfig) {
  // Define the order of labels as per your requirement
  const orderedLabels = ['Since Inception', '10 Year', '7 Year', '5 Year', '3 Year', '1 Year', 'YTD', '3 Months'];

  // Initialize variables for datasets and colors
  let datasets = [];
  let colorIndex = 0;
  const distinctColors = ['#36a2eb', '#ff6384', '#ffce56', '#9966ff', '#4bc0c0', '#ff9f40', '#9b59b6', '#e67e22', '#2ecc71', '#e74c3c', '#f39c12', '#3498db'];

  // Determine unique portfolios from the data
  const portfolios = Object.keys(data[Object.keys(data)[0]] || {});

  // Create a dataset for each portfolio
  portfolios.forEach(portfolio => {
    let portfolioData = orderedLabels.filter(label => data.hasOwnProperty(label)).map(label => {
      return data[label][portfolio] ? parseFloat(data[label][portfolio]) * 100 : NaN;
    });

    datasets.push({
      label: portfolio,
      data: portfolioData,
      borderColor: distinctColors[colorIndex % distinctColors.length],
      borderWidth: styleConfig.default.borderWidth,
      backgroundColor: distinctColors[colorIndex % distinctColors.length],
    });
    colorIndex++; // Move to next color for next portfolio
  });

  // Filter the labels based on available data
  const filteredLabels = orderedLabels.filter(label => data.hasOwnProperty(label));

  return { labels: filteredLabels, datasets };
}

function processConfidenceIntervalData(parsedData) {
  const datasets = [];
  let labels = [];
  const distinctColors = ['#36a2eb', '#ff6384', '#ffce56', '#9966ff', '#4bc0c0', '#ff9f40', '#9b59b6', '#e67e22', '#2ecc71', '#e74c3c', '#f39c12', '#3498db'];

  Object.keys(parsedData).forEach((portfolio, portfolioIndex) => {
    const portfolioData = JSON.parse(parsedData[portfolio]);
    const { columns, data, index } = portfolioData;
    const color = distinctColors[portfolioIndex % distinctColors.length]; // Cycle through color palette

    if (portfolioIndex === 0) { // Assuming all portfolios have the same labels
      labels = index.map(dateStr => new Date(dateStr));
    }

    const returnIndex = columns.indexOf('return');
    const lowerBoundIndex = columns.indexOf('lower_bound');
    const upperBoundIndex = columns.indexOf('upper_bound');

    // Main return data
    datasets.push({
      label: `${portfolio} Return`,
      data: data.map((row, i) => ({ x: labels[i], y: row[returnIndex] * 100 })),
      borderColor: color,
      backgroundColor: color,
      fill: false,
      pointRadius: 0, // Points are invisible by default
    });

    // Confidence interval (lower bound)
    datasets.push({
      label: `${portfolio} Lower Bound`,
      data: data.map((row, i) => ({ x: labels[i], y: row[lowerBoundIndex] * 100 })),
      borderColor: color,
      backgroundColor: `${color}40`, // For the fill color
      borderWidth: 0,
      fill: '+1', // Fill to next dataset (upper bound)
      pointRadius: 0,
    });

    // Confidence interval (upper bound)
    datasets.push({
      label: `${portfolio} Upper Bound`,
      data: data.map((row, i) => ({ x: labels[i], y: row[upperBoundIndex] * 100 })),
      borderColor: color,
      backgroundColor: `${color}40`, // For the fill color
      borderWidth: 0,
      fill: false,
      pointRadius: 0,
    });
  });

  return { labels, datasets };
}

function processScatterPlotData(parsedData, roles, styleConfig) {
  const datasets = [];
  const distinctColors = ['#36a2eb', '#ff6384', '#ffce56', '#9966ff', '#4bc0c0', '#ff9f40', '#9b59b6', '#e67e22', '#2ecc71', '#e74c3c', '#f39c12', '#3498db'];
  let colorIndex = 0;

  Object.keys(parsedData.returns).forEach((portfolioName) => {
    const role = roles[portfolioName] || 'default';
    const borderColor = distinctColors[colorIndex % distinctColors.length];
    colorIndex++;

    // Access the single numeric values directly
    const returnVal = parsedData.returns[portfolioName];
    const stdDevVal = parsedData.std_dev[portfolioName];

    // Since returns and std_dev are not arrays but single values, directly create the data array with a single object
    datasets.push({
      label: portfolioName, // Set the dataset label to the portfolio name
      data: [{
        x: stdDevVal * 100,
        y: returnVal * 100,
        // Add a custom property to store the tooltip label for each point
        tooltipLabel: `${portfolioName}: Std Dev ${stdDevVal.toFixed(2)}%, Return ${returnVal.toFixed(2)}%`,
      }],
      backgroundColor: borderColor,
      borderColor,
      borderWidth: styleConfig[role].borderWidth,
      pointRadius: 5,
      pointHoverRadius: 8,
      pointHoverBorderWidth: styleConfig[role].pointHoverBorderWidth,
    });
  });

  return {
    datasets,
  };
}

function renderChart(chartType, data, roles, styleConfig, adjAnalysisMethod, relative_name, lastDate) {
  const ctx = document.getElementById('analysisChart').getContext('2d');
  // Assume you store the client's name in session storage for simplicity
  const analysisMethod = sessionStorage.getItem('analysisMethod');
  const period = sessionStorage.getItem('period');
  const annualised = sessionStorage.getItem('annualised') === 'on' ? 'Annualised' : 'Cumulative';
  const nonTimeSeriesAnalysisMethods = ['calendar_returns', 'scatter_plot', 'period_returns'];

  // Constructing the chart title dynamically
  const chartTitle = transformTitle(adjAnalysisMethod, period, annualised, relative_name, lastDate);

  // Process data based on analysis method
  let { labels, datasets } = {};

  if (analysisMethod === 'returns' || analysisMethod === 'std_dev') {
    ({ labels, datasets } = processLineChartData(data, roles, styleConfig));
  } else if (analysisMethod === 'calendar_returns') {
    ({ labels, datasets } = processBarChartData(data, styleConfig));
  } else if (analysisMethod === 'confidence_intervals') {
    ({ labels, datasets } = processConfidenceIntervalData(data));
  } else if (analysisMethod === 'scatter_plot') {
    ({ labels, datasets } = processScatterPlotData(data, roles, styleConfig));
  } else if (analysisMethod === 'period_returns') {
    ({ labels, datasets } = processPeriodicChartData(data, styleConfig));
  }

  let scales = {};
  if (chartType === 'line') {
    scales = {
      x: {
        type: 'time',
        time: {
          parser: 'yyyy-MM-dd',
          unit: 'month',
          tooltipFormat: 'MMM dd, yyyy',
        },
        title: { display: true, text: 'Date' },
      },
      y: {
        beginAtZero: true,
        ticks: { callback: (value) => `${value}%` },
        title: { display: true, text: 'Percentage Return' },
      },
    };
  } else if (chartType === 'bar') {
    scales = {
      x: {
        type: 'category',
        title: { display: true, text: 'Year' },
      },
      y: {
        beginAtZero: true,
        ticks: { callback: (value) => `${value}%` },
        title: { display: true, text: 'Percentage Return' },
      },
    };
  } else if (chartType === 'scatter') {
    scales = {
      x: {
        ticks: { callback: (value) => `${value}%` },
        title: { display: true, text: 'Standard Deviation (%)' },
        beginAtZero: true,
      },
      y: {
        ticks: { callback: (value) => `${value}%` },
        title: { display: true, text: 'Returns (%)' },
        beginAtZero: true,
      },
    };
  }

  let datalabelsPlugin = null;
  if (nonTimeSeriesAnalysisMethods.includes(analysisMethod)) {
    datalabelsPlugin = {
      display: document.getElementById('toggleLabels').checked,
      color: '#444',
      align: 'end',
      anchor: 'end',
      // font: { weight: 'bold' },
      formatter: (value, context) => {
        if (analysisMethod === 'scatter_plot') {
          const dataPoint = context.dataset.data[context.dataIndex];
          return `${context.dataset.label}: ${dataPoint.y.toFixed(1)}%`;
        } else {
          const chart = context.chart;
          const datasetIndex = context.datasetIndex;
          const dataIndex = context.dataIndex;
          const dataValue = chart.data.datasets[datasetIndex].data[dataIndex];
          return `${dataValue.toFixed(1)}%`;
        }
      }
    };
  }

  const options = {
    scales: scales,
    plugins: {
      title: {
        display: true,
        text: chartTitle,
        font: { size: 16 },
      },
      datalabels: datalabelsPlugin,
    },
  };

  // Conditional modification for scatter plot tooltips
  if (analysisMethod === 'scatter_plot') {
    options.plugins.tooltip = {
      mode: 'point',
        intersect: true,
        callbacks: {
          label: function(context) {
            return context.raw.tooltipLabel || `${context.dataset.label}: ${context.parsed.y.toFixed(2)}%`;
      }
    },
  };
  } else {
    options.plugins.tooltip = {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label;
            let value = context.parsed.y.toFixed(2);
            return `${label}: ${value}%`;
          },
      },
    };
  }

  // Add datalabels plugin to options only if it's not null
  if (datalabelsPlugin !== null) {
    options.plugins.datalabels = datalabelsPlugin;
  }

  if (window.myChart instanceof Chart) {
    window.myChart.destroy();
  }

  window.myChart = new Chart(ctx, {
    type: chartType,
    data: {
      labels: labels,
      datasets: datasets
    },
    options: options
  });

  // Set the initial state of the toggleLabels checkbox based on the analysisMethod
  const toggleLabelsCheckbox = document.getElementById('toggleLabels');
  const toggleLabelsDiv = document.getElementById('toggleLabelsDiv');
  if (toggleLabelsCheckbox) {
    toggleLabelsCheckbox.checked = false;
    if (datalabelsPlugin !== null) {
      toggleLabelsDiv.style.display = 'inline-block';
    } else {
      toggleLabelsDiv.style.display = 'none';
    }
  }

  // Show or hide the date range controls based on the analysisMethod
  if (nonTimeSeriesAnalysisMethods.includes(analysisMethod) && analysisMethod !== 'calendar_returns') {
    document.getElementById('dateRangeControls').style.display = 'block';
    document.getElementById('startDateCol').style.display = 'none';
  } else {
    document.getElementById('dateRangeControls').style.display = 'block';
    document.getElementById('startDateCol').style.display = 'block';
  }

  // Extract the end_date from the data if applicable
  let endDate = null;
  if (nonTimeSeriesAnalysisMethods.includes(analysisMethod) && analysisMethod !== 'calendar_returns') {
    endDate = lastDate;
    // Set the value of the endDate input field
    document.getElementById('endDate').value = formatDate(new Date(endDate));
  } else {
    const dates = labels.map(label => new Date(label));
    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));

    // Set the min and max attributes of the date input fields
    document.getElementById('startDate').min = formatDate(minDate);
    document.getElementById('startDate').max = formatDate(maxDate);
    document.getElementById('endDate').min = formatDate(minDate);
    document.getElementById('endDate').max = formatDate(maxDate);

    // Set the initial values of the date input fields to the min and max dates
    document.getElementById('startDate').value = formatDate(minDate);
    document.getElementById('endDate').value = formatDate(maxDate);
  }

  // Remove the existing event listener
  document.getElementById('updateChart').removeEventListener('click', updateChartHandler);

  // Add event listener to updateChart button
  document.getElementById('updateChart').addEventListener('click', updateChartHandler);
}

function updateChartHandler() {
  // Retrieve the latest analysisMethod value from sessionStorage or your source of truth
  const currentAnalysisMethod = sessionStorage.getItem('analysisMethod');
  const nonTimeSeriesAnalysisMethods = ['scatter_plot', 'period_returns'];

  if (nonTimeSeriesAnalysisMethods.includes(currentAnalysisMethod)) {
    const endDate = document.getElementById('endDate').value;

    // Create the URL to rerun the analysis with the new date range
    const clientId = sessionStorage.getItem('clientId');
    const relativeId = sessionStorage.getItem('relativeId');
    const period = sessionStorage.getItem('period');
    const annualised = sessionStorage.getItem('annualised');
    const strategyLevel = sessionStorage.getItem('strategyLevel');
    const portfolioIds = sessionStorage.getItem('portfolioIds');
    const benchmarkIds = sessionStorage.getItem('benchmarkIds');
    const targetIds = sessionStorage.getItem('targetIds');
    const url = `/prasys_app/rerun-analysis/?client_id=${clientId}&portfolio_ids=${portfolioIds}&benchmark_ids=${benchmarkIds}&target_ids=${targetIds}&relative_id=${relativeId}&period=${period}&annualised=${annualised === 'on'}&analysis_to_perform=${currentAnalysisMethod}&strategy_level=${strategyLevel}&end_date=${endDate}`;

    fetch(url)
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        // Fetch the updated analysis results after a short delay to allow the Celery task to complete
        setTimeout(() => {
          const analysisUrl = getAnalysisUrl(endDate);
          fetch(analysisUrl)
            .then(response => response.json())
            .then(data => processAnalysisResults(data))
            .catch(error => console.error('Error fetching updated analysis results:', error));
        }, 2000); // Adjust the delay as needed
      } else {
        console.error('Error rerunning analysis:', data.message);
      }
    })
    .catch(error => console.error('Error rerunning analysis:', error));

  } else {
    const startDate = new Date(document.getElementById('startDate').value);
    const endDate = new Date(document.getElementById('endDate').value);

    // Filter the data based on the selected date range
    const filteredData = {
      labels: window.myChart.data.labels.filter(label => {
        const date = new Date(label);
        return date >= startDate && date <= endDate;
      }),
      datasets: window.myChart.data.datasets.map(dataset => ({
        ...dataset,
        data: dataset.data.filter((_, index) => {
          const date = new Date(window.myChart.data.labels[index]);
          return date >= startDate && date <= endDate;
        })
      }))
    };

    // Update the chart title with the new end date
    let relativeId = sessionStorage.getItem('relativeId');
    let analysisMethod = sessionStorage.getItem('analysisMethod');
    const period = sessionStorage.getItem('period');
    const annualised = sessionStorage.getItem('annualised') === 'on' ? 'Annualised' : 'Cumulative';
    const relative_name = sessionStorage.getItem('relative_name');
    const newEndDate = endDate.toISOString().split('T')[0]; // Format the end date as 'YYYY-MM-DD'

    // Handle null or empty relativeId
    if (relativeId) {
      analysisMethod = `relative_${analysisMethod}`;
    }

    const updatedTitle = transformTitle(analysisMethod, period, annualised, relative_name, newEndDate);
    window.myChart.options.plugins.title.text = updatedTitle;

    // Update the chart with the filtered data
    window.myChart.data = filteredData;
    window.myChart.update();
  }
}

function formatDate(date) {
  // Helper function to format a date as YYYY-MM-DD
  return date.toISOString().split('T')[0];
}

function getAnalysisUrl(endDate = null) {
  // Fetch analysis results from session storage
  let relativeId = sessionStorage.getItem('relativeId');
  let analysisMethod = sessionStorage.getItem('analysisMethod');
  let clientId = sessionStorage.getItem('clientId');
  const period = sessionStorage.getItem('period');
  const annualised = sessionStorage.getItem('annualised');
  const strategyLevel = sessionStorage.getItem('strategyLevel');
  let combinedIds = sessionStorage.getItem('combinedIds');

  // Sort combinedIds numerically before constructing the URL
  if (combinedIds) {
    combinedIds = combinedIds.split(',')
                            .map(id => parseInt(id, 10))  // Convert to integers
                            .sort((a, b) => a - b)        // Numeric sorting
                            .join(',');
  }

  // Handle null or empty clientId
  if (!clientId) {
    clientId = 'no_client'; // Use the same default value as in the backend
  }

  // Handle null or empty relativeId
  if (!relativeId) {
    relativeId = 'None';
  } else {
    analysisMethod = `relative_${analysisMethod}`;
  }

  let url = `/prasys_app/get-analysis-results/${clientId}/${analysisMethod}/${period}/${strategyLevel}/${annualised === 'on' ? 'annualised' : 'cumulative'}/${combinedIds}/${relativeId}/`;

  url += `${endDate || 'None'}`;

  return url;
}

function processAnalysisResults(data) {
  if (data.error) {
    console.error('Failed to fetch analysis results:', data.error);
  } else {
    // Determine categories present in the data to dynamically set borderDash styles
    const categories = Object.values(data.roles);
    const borderDashStyles = [
      [], // Style for 'no dash'
      [3, 3], // Style for 'dash'
      [10, 5] // Style for 'long dash'
    ];

    // Initialize styleConfig with default settings
    const styleConfig = {
      default: {
        borderColor: '#6c757d',
        borderWidth: 2,
        borderDash: [],
        pointHoverBorderWidth: 2,
      }
    };

    // Logic to dynamically assign borderDash styles based on category presence
    let styleIndex = 0; // Start with the 'no dash' style

    // Function to get the next borderDash style
    const getNextStyle = () => borderDashStyles[styleIndex++ % borderDashStyles.length];

    // Prioritize 'portfolio' for 'no dash', if present
    if (categories.includes('portfolio')) {
      styleConfig['portfolio'] = {
        borderWidth: 3,
        borderDash: getNextStyle(), // Assign 'no dash' and move to next style
        pointHoverBorderWidth: 3,
      };
    }

    // Assign styles to 'target' and 'benchmark', use 'no dash' if 'portfolio' is not present
    ['benchmark', 'target'].forEach(category => {
      if (categories.includes(category)) {
        styleConfig[category] = {
          borderWidth: 2,
          borderDash: getNextStyle(), // Dynamically assign next available style
          pointHoverBorderWidth: 3,
        };
      }
    });

    // Call renderChart with the updated styleConfig
    const analysisType = data.analysis_type;
    const parsedData = JSON.parse(data.results);
    const lastDate = data.last_date;
    let analysisMethod = sessionStorage.getItem('analysisMethod');
    let relativeId = sessionStorage.getItem('relativeId');

    // Handle null or empty relativeId
    if (!relativeId) {
      relativeId = 'None';
    } else {
      analysisMethod = `relative_${analysisMethod}`;
    }

    switch (analysisType) {
      case 'line_chart':
        renderChart('line', parsedData, data.roles, styleConfig, analysisMethod, data.relative_name, lastDate);
        break;
      case 'bar_chart':
        renderChart('bar', parsedData, data.roles, styleConfig, analysisMethod, data.relative_name, lastDate);
        break;
      case 'scatter_plot':
        renderChart('scatter', parsedData, data.roles, styleConfig, analysisMethod, data.relative_name, lastDate);
        break;
      // Add more cases for other chart types as needed
      default:
        console.error(`Unknown analysis type: ${analysisType}`);
    }
    document.getElementById('analysisResults').style.display = 'block';
  }
}

function fetchAnalysisResults() {
  const url = getAnalysisUrl();

  fetch(url)
    .then((response) => response.json())
    .then((data) => processAnalysisResults(data))
    .catch((error) => console.error('Error fetching analysis results:', error));
}

function downloadChart(vanvasId) {
  var canvas = document.getElementById(vanvasId);
  var imageURL = canvas
    .toDataURL('image/png')
    .replace('image/png', 'image/octet-stream');
  var tempLink = document.createElement('a');
  tempLink.href = imageURL;
  tempLink.download = 'chart.png';
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
}

/* progress_bar_update.html */
document.addEventListener('DOMContentLoaded', (event) => {
  const progressBarContainer = document.getElementById('progressBarContainer');

  if (progressBarContainer) {
    const taskId = progressBarContainer.getAttribute('data-task-id');
    // Create a new WebSocket for task updates
    const ws_scheme = window.location.protocol === 'https:' ? 'wss' : 'ws';

    // Determine the appropriate WebSocket port based on the environment
    let ws_port = '';
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    ) {
      // Use port 8000 for development environment
      ws_port = ':8000';
    }

    const ws_path =
      ws_scheme +
      '://' +
      window.location.hostname +
      ws_port +
      '/ws/progress_updates/' +
      taskId +
      '/';
    const ws = new WebSocket(ws_path);

    ws.onopen = function () {
      const progressStatus = document.getElementById('progressStatus');
      if (progressStatus) {
        progressStatus.textContent = 'Connected. Waiting for updates...';
      }
    };

    ws.onmessage = function (e) {
      const data = JSON.parse(e.data);
      const progressBar = document.getElementById('progressBar');
      const progressStatus = document.getElementById('progressStatus');

      if (progressBar && data.progress) {
        progressBar.style.width = data.progress + '%';
        progressBar.setAttribute('aria-valuenow', data.progress);
        progressBar.textContent = data.progress + '%';
      }

      if (progressStatus && data.message) {
        progressStatus.textContent = data.message;
      }
    };

    ws.onclose = function (e) {
      console.log('WebSocket closed with code:', e.code, 'reason:', e.reason);
      const progressStatus = document.getElementById('progressStatus');
      if (progressStatus) {
        progressStatus.textContent = 'Task completed or connection closed.';
      }
    };

    ws.onerror = function (e) {
      console.log('WebSocket error:', e);
      const progressStatus = document.getElementById('progressStatus');
      if (progressStatus) {
        progressStatus.textContent = 'WebSocket connection error.';
      }
    };
  }
});

/* Check task status */
document.addEventListener('DOMContentLoaded', (event) => {
  // Retrieve container elements efficiently
  const progressBarContainer = document.getElementById('progressBarContainer');
  const almRedirect = document.getElementById('almRedirect');

  if (progressBarContainer) {
    const taskId = progressBarContainer.getAttribute('data-task-id');
    const clientId = progressBarContainer.getAttribute('data-client-id');
    const sessionKey = progressBarContainer.getAttribute('data-session-key');

    // Define statusCheckURL
    let statusCheckURL = `/data/check-task-status/${taskId}/`;
    // Function to handle status check, redirection, and error handling
    const handleTaskStatus = () => {
      fetch(statusCheckURL)
        .then((response) => response.json())
        .then((data) => {
          switch (data.state) {
            case 'SUCCESS':
              document.getElementById('progressStatus').textContent =
                `Task completed successfully`;
              clearInterval(pollInterval);
              setTimeout(() => {
                if (almRedirect) {
                  const redirectUrl = `/alm/results/${clientId}/${sessionKey}/`;
                  window.location.href = redirectUrl;
                }
              }, 5000);
              break;
            case 'FAILURE':
              document.getElementById('progressStatus').textContent =
                `Error: ${data.details.error || 'Task failed with an error.'}`;
              clearInterval(pollInterval);
              break;
            default:
          }
        })
        .catch((error) => {
          console.error('Error fetching task status:', error);
          document.getElementById('progressStatus').textContent =
            'Error checking task status.';
          clearInterval(pollInterval);
        });
    };

    // Check task status initially and every 2 seconds
    handleTaskStatus();
    const pollInterval = setInterval(handleTaskStatus, 2000);
  }
});

/* prasys_app/analysis_home.html */
document.addEventListener('DOMContentLoaded', function () {
  const analysisForm = document.getElementById('analysisForm');
  if (analysisForm) {
    const clientSelect = document.querySelector('select[name="client"]');
    const levelSelect = document.querySelector('select[name="strategy_level"]');
    const analysisSelect = document.querySelector('select[name="analysis_to_perform"]');
    const periodSelect = document.querySelector('select[name="period"]');
    const periodInput = document.querySelector('select[name="period"]');
    const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    const form = document.getElementById('analysisForm');
    const periodDiv = document.querySelector('.period');
    const annualisedDiv = document.querySelector('.annualised');
    const investmentlevelDiv = document.querySelector('.strategy_level');
    const portfolioDiv = document.querySelector('.portfolio');
    const relativeDiv = document.querySelector('.relative');
    const portfolioSelects = document.querySelectorAll('select[name="portfolio_name"]');
    const benchmarkSelects = document.querySelectorAll('select[name="benchmark"]');
    const targetSelects = document.querySelectorAll('select[name="target"]');

    // Initial setup
    removeUnwantedPeriodOption(periodInput);
    onClientOrLevelChange(clientSelect, levelSelect, csrftoken);
    toggleFieldsBasedOnAnalysis(analysisSelect, periodDiv, annualisedDiv, investmentlevelDiv, portfolioDiv, relativeDiv);
    toggleFieldsBasedOnPeriod(periodInput, annualisedDiv, analysisSelect);

    // Event listeners
    clientSelect.addEventListener('change', function () {
      onClientOrLevelChange(clientSelect, levelSelect, csrftoken);
    });

    levelSelect.addEventListener('change', function () {
      onClientOrLevelChange(clientSelect, levelSelect, csrftoken);
    });

    analysisSelect.addEventListener('change', function () {
      toggleFieldsBasedOnAnalysis(analysisSelect, periodDiv, annualisedDiv, investmentlevelDiv, portfolioDiv, relativeDiv);
      toggleFieldsBasedOnPeriod(periodInput, annualisedDiv, analysisSelect);
    });

    periodSelect.addEventListener('change', function () {
      toggleFieldsBasedOnPeriod(periodInput, annualisedDiv, analysisSelect);
    });

    // Event listener for toggleLabels checkbox
    const toggleLabelsCheckbox = document.getElementById('toggleLabels');
    if (toggleLabelsCheckbox) {
      toggleLabelsCheckbox.checked = false;
      toggleLabelsCheckbox.addEventListener('change', function(e) {
        const shouldDisplayLabels = e.target.checked;
        // Check if the chart instance exists before trying to update it
        if (window.myChart && window.myChart.options && window.myChart.options.plugins) {
          window.myChart.options.plugins.datalabels.display = shouldDisplayLabels;
          window.myChart.update();
        }
      });
    }

    // Form handling
    form.addEventListener('submit', function (e) {
      e.preventDefault();

      // Prepare FormData for submission
      const formData = new FormData(form);
      const selectedAnalysis = formData.get('analysis_to_perform');

      // Logic for collecting selected portfolio, benchmark, and target IDs
      const portfolioIds = Array.from(portfolioSelects).flatMap(select => Array.from(select.selectedOptions).map(option => option.value)).filter(id => id !== '');
      const benchmarkIds = Array.from(benchmarkSelects).flatMap(select => Array.from(select.selectedOptions).map(option => option.value)).filter(id => id !== '');
      const targetIds = Array.from(targetSelects).flatMap(select => Array.from(select.selectedOptions).map(option => option.value)).filter(id => id !== '');

      // Store values in sessionStorage for later use
      sessionStorage.setItem('portfolioIds', portfolioIds);
      sessionStorage.setItem('benchmarkIds', benchmarkIds);
      sessionStorage.setItem('targetIds', targetIds);

      // Combine and sort IDs
      const combinedIds = [...portfolioIds, ...benchmarkIds, ...targetIds]

      // Logic to adjust FormData based on analysis type
      // Store values in sessionStorage for potential later use
      sessionStorage.setItem('clientId', formData.get('client'));
      sessionStorage.setItem('portfolioId', formData.get('portfolio_name'));
      // Conditionally set 'period' to '12' if the selected analysis is 'calendar_returns'
      if (
        selectedAnalysis === 'calendar_returns'
      ) {
        sessionStorage.setItem('period', '12'); // Set period to '12' when analysis is 'calendar_returns'
      } else {
        sessionStorage.setItem('period', formData.get('period')); // Otherwise, use the selected period value
      }
      // Conditionally set 'annualised' to 'false' if the selected analysis is 'confidence_intervals'
      if (
        selectedAnalysis === 'calendar_returns'
      ) {
        sessionStorage.setItem('annualised', 'on');
        formData.set('annualised', 'on');
      } else if (selectedAnalysis === 'confidence_intervals' | selectedAnalysis === 'scatter_plot' | selectedAnalysis === 'period_returns') {
        sessionStorage.setItem('annualised', 'on');
        formData.set('annualised', 'on');
      } else {
        sessionStorage.setItem('annualised', formData.get('annualised'));
      }
      sessionStorage.setItem(
        'analysisMethod',
        formData.get('analysis_to_perform'),
      );
      sessionStorage.setItem('combinedIds', combinedIds);
      sessionStorage.setItem('strategyLevel', formData.get('strategy_level'));
      if (formData.get('relative') === null) {
        formData.delete('relative'); // If not present, ensure it's not included in the FormData
      } else {
          sessionStorage.setItem('relativeId', formData.get('relative'));
      }

      // Submit the form
      fetch(form.action, {
        method: 'POST',
        body: formData,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': csrftoken,
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.task_id) {
            // Subscribe to WebSocket updates for this task
            subscribeToTaskUpdates(data.task_id);
            // Check task status every 2 seconds
            checkTaskStatus(data.task_id);
          } else if (data.errors) {
            console.error('Form errors:', data.errors);
            // Handle form validation errors
          }
        })
        .catch(error => console.error('Error submitting form:', error));
    });
  }
});

/* constraints.html */
document.addEventListener('DOMContentLoaded', function () {
  if (document.getElementById('constraint_form')) {
    // Fetch constraint variables from the server
    fetch('/optimisation/get-constraint-variables/')
      .then((response) => response.json())
      .then((data) => {
        // Store fetched data into variables
        const columnNames = data.columnNames;
        const class1Choices = Object.entries(data.class1Choices);
        const class2Choices = Object.entries(data.class2Choices);

        // Initialize form with fetched data
        initializeForm(columnNames, class1Choices, class2Choices);
      })
      .catch((error) =>
        console.error('Error fetching constraint variables:', error),
      );
  }
});

/* alm/life_stage_data_form.html */
function updateFormRowIndices(row, newIndex) {
  [...row.querySelectorAll('input, select, textarea, label')].forEach(
    (element) => {
      const name = element.name;
      const id = element.id;
      if (name) element.name = name.replace(/\d+/, newIndex);
      if (id) element.id = id.replace(/\d+/, newIndex);
      if (element.tagName === 'LABEL' && element.htmlFor)
        element.htmlFor = element.htmlFor.replace(/\d+/, newIndex);

      // Reset input/textarea values, deselect selects
      if (element.tagName !== 'LABEL') {
        if (element.type === 'checkbox' || element.type === 'radio') {
          element.checked = false;
        } else if (element.tagName === 'SELECT') {
          element.selectedIndex = 0;
        } else {
          element.value = '';
        }
      }
    },
  );
  row.setAttribute('data-form-index', newIndex);
}

function updateFollowingRowsIndices(formsetContainer) {
  const allRows = formsetContainer.querySelectorAll(
    '.custom-form-row[data-form-index]',
  );
  allRows.forEach((row, index) => {
    updateFormRowIndices(row, index);
  });
}

function updateRiskFactor(row, index) {
  const riskFactorSelect = row.querySelector(
    'select[name^="life_stage-' + index + '-risk_factor"]',
  );
  if (riskFactorSelect) {
    // Set initial value based on the current selection
    const initialValue = RISK_FACTOR_VALUES_JS[riskFactorSelect.value];

    riskFactorSelect.addEventListener('change', function () {
      // Update logic on change
      const selectedValue = RISK_FACTOR_VALUES_JS[riskFactorSelect.value];
    });
  }
}

function reapplyRetirementRowDisplay() {
  const formsetContainer = document.getElementById('formset_container');
  const retirementAge = formsetContainer.getAttribute(
    'data-retirement-age',
  );
  const memberWorkStartAge = formsetContainer.getAttribute('data-member-work-start-age');

  // Assuming the retirement row is always the last one
  const retirementRow = document.querySelector(
    '.custom-form-row:last-child',
  );
  if (!retirementRow) return;

  // Reapply life stage name value
  const lifeStageNameInput = retirementRow.querySelector(
    'input[name$="life_stage_name"]',
  );
  if (lifeStageNameInput) lifeStageNameInput.value = 'Retirement';

  // Reapply age value
  const ageInput = retirementRow.querySelector('input[name$="age"]');
  if (ageInput && retirementAge) ageInput.value = retirementAge;

  // Reapply risk factor value
  const riskFactorSelect = retirementRow.querySelector(
    'select[name$="risk_factor"]',
  );
  if (riskFactorSelect) {
    const conservativeOption = Array.from(riskFactorSelect.options).find(
      (option) => option.value === 'Conservative',
    );
    if (conservativeOption) conservativeOption.selected = true;
  }

  // Reapply age value for the first row and disable the input field
  const firstRow = document.querySelector('.custom-form-row[data-form-index="0"]');
  if (firstRow) {
    const ageInput = firstRow.querySelector('input[name$="age"]');
    if (ageInput && memberWorkStartAge) {
      ageInput.value = memberWorkStartAge;
      ageInput.disabled = true;
    }
  }
}

document.addEventListener('DOMContentLoaded', function () {
  const addButton = document.getElementById('add_more');
  if (!addButton) return;
  // JavaScript mirror of the RISK_FACTOR_VALUES from Python
  const RISK_FACTOR_VALUES_JS = {
    Aggressive: 1.0,
    Moderate: 0.4,
    Conservative: 0.2,
  };

  // Set the age input value and disable the field for the first row on page load
  const formsetContainer = document.getElementById('formset_container');
  const memberWorkStartAge = formsetContainer.getAttribute('data-member-work-start-age');
  const firstRowAgeInput = document.querySelector('.custom-form-row[data-form-index="0"] input[name$="age"]');

  if (firstRowAgeInput && memberWorkStartAge) {
    // Ensure the visible input is disabled
    firstRowAgeInput.disabled = true;
    firstRowAgeInput.value = memberWorkStartAge;

    // Check for an existing hidden input; if none, create it
    let hiddenInput = firstRowAgeInput.nextElementSibling;
    if (!hiddenInput || hiddenInput.type !== 'hidden') {
      hiddenInput = document.createElement('input');
      hiddenInput.type = 'hidden';
      hiddenInput.name = firstRowAgeInput.name; // Ensure it has the same name for submission
      firstRowAgeInput.parentNode.insertBefore(hiddenInput, firstRowAgeInput.nextSibling);
    }
    // Set the hidden input's value so it gets submitted
    hiddenInput.value = memberWorkStartAge;
  }

  addButton.addEventListener('click', function () {
    let totalFormsInput = document.getElementById('id_life_stage-TOTAL_FORMS');
    let currentTotalForms = parseInt(totalFormsInput.value, 10);

    // Clone the row you want to copy
    let newRow = formsetContainer
      .querySelector('.custom-form-row[data-form-index="0"]')
      .cloneNode(true);

    // Remove the hidden input from the cloned row
    const hiddenInput = newRow.querySelector('input[type="hidden"]');
    if (hiddenInput) {
      hiddenInput.remove();
    }

    // Update newRow index and reset values
    updateFormRowIndices(newRow, currentTotalForms);

    // Insert newRow before the retirement row
    let retirementRow = formsetContainer.querySelector(
      '.custom-form-row[data-form-index="' + (currentTotalForms - 1) + '"]',
    );
    formsetContainer.insertBefore(newRow, retirementRow);

    // Update the TOTAL_FORMS count
    totalFormsInput.value = currentTotalForms + 1;

    // Correctly update indices for rows after the newly inserted one, including the retirement row
    updateFollowingRowsIndices(formsetContainer);

    // Update risk_factor values based on mapping
    updateRiskFactor(newRow, currentTotalForms);

    // Enable the ageInput field for the newly added row
    const ageInput = newRow.querySelector('input[name$="age"]');
    if (ageInput) {
      ageInput.disabled = false;
    }

    // Reapply retirement row display values
    reapplyRetirementRowDisplay();

    // Add event listener to the ageInput select
    document.querySelector('form').addEventListener('submit', function(event) {
      const firstRowAgeInput = document.querySelector('.custom-form-row[data-form-index="0"] input[name$="age"]');
      const hiddenInput = document.querySelector('.custom-form-row[data-form-index="0"] input[type="hidden"]');

      if (firstRowAgeInput && hiddenInput) {
        const memberWorkStartAge = formsetContainer.getAttribute('data-member-work-start-age');
        if (!firstRowAgeInput.value) {
          firstRowAgeInput.value = memberWorkStartAge;
        }
        hiddenInput.value = memberWorkStartAge;
      }
    });
  });
});

/* alm/results.html */
function updateAgeRangeInputs(fullChartData) {
  const allAges = fullChartData.labels.map(Number);
  const minAge = Math.min(...allAges);
  const maxAge = Math.max(...allAges);
  document.getElementById('minAgeRange').min = minAge;
  document.getElementById('minAgeRange').max = maxAge;
  document.getElementById('minAgeRange').value = minAge;
  document.getElementById('maxAgeRange').min = minAge;
  document.getElementById('maxAgeRange').max = maxAge;
  document.getElementById('maxAgeRange').value = maxAge;
  document.getElementById('minAgeLabel').textContent = minAge;
  document.getElementById('maxAgeLabel').textContent = maxAge;
}

function updateChart(retirementAge) {
  const chart = window.chart;
  const fullChartData = window.fullChartData;
  const ctx = document.getElementById('assetPathsChart').getContext('2d');

  if (!fullChartData || !ctx) return; // Exit if fullChartData or context is not available

  // Destroy the existing chart instance if present
  if (chart) chart.destroy();

  const settings = getUserSettings();
  const { minAge, maxAge, numPortfolios, dataType, isCurrency } = settings;
  const chartLabel = formatChartLabel(dataType);

  let filteredLabels;
  let filteredDatasets;

  if (dataType === "replacement_ratio_distribution") {
    filteredLabels = fullChartData.labels;
    filteredDatasets = fullChartData.datasets;
  } else {
    ({ labels: filteredLabels, datasets: filteredDatasets } = getFilteredData(fullChartData, minAge, maxAge, numPortfolios));
  }

  window.chart = new Chart(ctx, configureChart(filteredLabels, filteredDatasets, isCurrency, chartLabel, retirementAge, dataType));
  return window.chart;
}

function getUserSettings() {
  const minAge = parseInt(document.getElementById('minAgeRange').value, 10);
  const maxAge = parseInt(document.getElementById('maxAgeRange').value, 10);
  const numPortfolios = parseInt(document.getElementById('numPortfolios').value, 10);
  const dataType = document.getElementById('dataTypeSelect').value;
  const isCurrency = dataType !== 'replacement_ratios' && dataType !== 'replacement_ratio_distribution' && dataType !== 'depletion_risk';
  return { minAge, maxAge, numPortfolios, dataType, isCurrency };
}

function formatChartLabel(dataType) {
  return dataType.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
}

function getFilteredData(fullChartData, minAge, maxAge, numPortfolios) {
  // Filter labels to include only those within the minAge and maxAge range.
  const filteredLabels = fullChartData.labels.filter(age => age >= minAge && age <= maxAge);

  // Filter each dataset to include data only for the filtered labels.
  const filteredDatasets = fullChartData.datasets.slice(0, numPortfolios).map(dataset => {
    const filteredDataIndices = filteredLabels.map(age => fullChartData.labels.indexOf(age));
    const filteredData = filteredDataIndices.map(index => dataset.data[index]);
    return { ...dataset, data: filteredData };
  });

  return { labels: filteredLabels, datasets: filteredDatasets };
}

function configureChart(labels, datasets, isCurrency, chartLabel, retirementAge, dataType) {
  // Adjust retirementAge for depletion_risk
  retirementAge = dataType === 'depletion_risk' ? 90 : retirementAge;

  // Define a list of dataTypes where datalabels should not be displayed
  const excludedDataTypes = ['male_salary', 'female_salary', 'contributions', 'withdrawals', 'replacement_ratio_distribution'];

  return {
    type: 'line',
    data: { labels, datasets },
    options: {
      scales: {
        x: { title: { display: true, text: dataType === "replacement_ratio_distribution" ? 'Replacement Ratio %' : 'Age' } },
        y: {
          beginAtZero: true,
          title: { display: true, text: dataType === "replacement_ratio_distribution" ? 'Distribution %' : '' },
          ticks: {
            callback: value => isCurrency ? `R ${value.toLocaleString()}` : `${value}%`
          }
        }
      },
      plugins: {
        title: {
          display: true,
          text: chartLabel,
          padding: { top: 10, bottom: 30 },
          font: { size: 16 }
        },
        legend: {
          labels: {
            font: { size: 12 }
          }
        },
        datalabels: {
          display: function(context) {
            // Apply only to the median dataset, the specific data point, and not excluded dataTypes
            return context.datasetIndex === 0 &&
                   context.chart.data.labels[context.dataIndex] == retirementAge &&
                   !excludedDataTypes.includes(dataType);
          },
          anchor: 'end',
          align: 'left',
          offset: 10, // Adjust as needed to fine-tune position
          color: 'red', // Highlight color
          backgroundColor: 'white', // Background color to stand out
          borderRadius: 4,
          font: {
            weight: 'bold',
            size: 14, // Larger font size for emphasis
          },
          formatter: function(value, context) {
            // Format value to one decimal place
            return isCurrency ? `R ${parseFloat(value).toFixed(1)}` : `${parseFloat(value).toFixed(1)}%`;
          }
        }
      },
      transitions: {
        show: {
          animations: {
            x: { from: 0 },
            y: { from: 0 }
          }
        },
        hide: {
          animations: {
            x: { to: 0 },
            y: { to: 0 }
          }
        }
      }
    }
  };
}

function fetchAndUpdateChart(clientId, sessionKey, retirementAge) {
  const dataType = document.getElementById('dataTypeSelect').value;
  const numPortfolios = parseInt(document.getElementById('numPortfolios').value, 10);
  const url = `/alm/get-chart-data/${dataType}/${clientId}/${sessionKey}/${retirementAge}/?num_portfolios=${numPortfolios}`;

  // Get the toggle elements
  const medianToggleDiv = document.getElementById('medianToggleDiv');
  const portfolioToggleDiv = document.getElementById('portfolioToggleDiv');
  const numPortfoliosDiv = document.getElementById('numPortfoliosDiv');
  const rangeSelector = document.getElementById('rangeSelector');

  // Data types that should hide the toggle elements
  const dataTypesToHide = ['replacement_ratios', 'replacement_ratio_distribution', 'depletion_risk'];

  // Show or hide the toggle elements if dataType is in the dataTypesToHide list
  if (dataTypesToHide.includes(dataType)){
    if (medianToggleDiv) medianToggleDiv.style.display = 'none';
    if (portfolioToggleDiv) portfolioToggleDiv.style.display = 'none';
    if (numPortfoliosDiv) numPortfoliosDiv.style.display = 'none';
    if (rangeSelector) {
      rangeSelector.style.display = 'none';
      rangeSelector.style.visibility = 'hidden';
      rangeSelector.style.height = '0';
    }
  } else {
    if (medianToggleDiv) medianToggleDiv.style.display = 'block';
    if (portfolioToggleDiv) portfolioToggleDiv.style.display = 'block';
    if (numPortfoliosDiv) numPortfoliosDiv.style.display = 'block';
    if (rangeSelector) {
      rangeSelector.style.display = 'flex';
      rangeSelector.style.visibility = 'visible';
      rangeSelector.style.height = 'auto';
    }
  }

  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      window.fullChartData = data; // Update the global fullChartData
      updateAgeRangeInputs(data); // Use the updated fullChartData

      // Update or create the chart
      if (window.chart) window.chart.destroy();
      window.chart = updateChart(retirementAge); // Update the global chart instance

      // Reset the median and portfolio toggles if they are visible
      const medianToggle = document.getElementById('medianToggle');
      const portfolioToggle = document.getElementById('portfolioToggle');
      if (medianToggle && medianToggleDiv.style.display !== 'none') medianToggle.checked = true;
      if (portfolioToggle && portfolioToggleDiv.style.display !== 'none') portfolioToggle.checked = true;
    })
    .catch((error) => console.error('Error fetching chart data:', error));
}

function updateToggleVisibility(dataType, togglesContainer) {
  const dataTypesToHide = ['replacement_ratios', 'depletion_risk'];
  if (dataTypesToHide.includes(dataType)) {
    togglesContainer.style.display = 'none';
  } else {
    togglesContainer.style.display = 'flex'; // Use 'block' if 'flex' doesn't apply
  }
}

/* alm page */
document.addEventListener('DOMContentLoaded', function () {
  var resultsPageContent = document.getElementById('resultsTabContent');
  if (!resultsPageContent) {
    return;
  }

  // Initialize global variables
  window.chart = null;
  window.fullChartData = null;
  window.optimalChart = null;
  window.fullOptimalChartData = null;

  var clientId = resultsPageContent.getAttribute('data-client-id');
  var sessionKey = resultsPageContent.getAttribute('data-session-key');
  var retirementAge = resultsPageContent.getAttribute('data-retirement-age');

  // Initialize event listeners for dropdown, number of portfolios input changes, and range inputs for the main chart
  document.getElementById('dataTypeSelect').addEventListener('change', () => fetchAndUpdateChart(clientId, sessionKey, retirementAge));
  document.getElementById('numPortfolios').addEventListener('change', () => fetchAndUpdateChart(clientId, sessionKey, retirementAge));

  // Initialize chart fetch/update when Charts tab is first shown or page loads
  var chartsTab = document.querySelector('#charts-tab');
  if (chartsTab) {
    chartsTab.addEventListener('shown.bs.tab', () => {
      fetchAndUpdateChart(clientId, sessionKey, retirementAge);
    });
  } else {
    fetchAndUpdateChart(clientId, sessionKey, retirementAge); // Fetch data on page load if not relying on tab shown event
  }

  // Download chart data as CSV
  document.getElementById('downloadChart').addEventListener('click', function () {
    downloadChart('assetPathsChart');
  });

  // Initialize age input for main chart
  document.getElementById('minAgeRange').addEventListener('input', function () {
    if (window.fullChartData && window.chart) {
      window.chart.destroy(); // Ensure the previous chart instance is destroyed
      window.chart = updateChart(retirementAge); // Create a new chart instance
    }
  });
  document.getElementById('maxAgeRange').addEventListener('input', function () {
    if (window.fullChartData && window.chart) {
      window.chart.destroy();
      window.chart = updateChart(retirementAge);
    }
  });

  var medianToggle = document.getElementById('medianToggle');
  var portfolioToggle = document.getElementById('portfolioToggle');

  if (medianToggle) {
    medianToggle.addEventListener('change', function () {
      if (window.chart && window.chart.data.datasets.length > 0) {
        window.chart.data.datasets[0].hidden = !this.checked;
        window.chart.update();
      }
    });
  }

  if (portfolioToggle) {
    portfolioToggle.addEventListener('change', function () {
      if (window.chart && window.chart.data.datasets.length > 1) {
        window.chart.data.datasets.forEach((dataset, index) => {
          if (index !== 0) { // Skip the first dataset (median)
            dataset.hidden = !this.checked;
          }
        });
        window.chart.update();
      }
    });
  }

  // Get references to the toggle elements and their container for the main chart
  var togglesContainer = document.getElementById('toggles');
  var dataTypeSelect = document.getElementById('dataTypeSelect');

  // Listen for changes on the dataTypeSelect dropdown for the main chart
  if (dataTypeSelect && togglesContainer) {
    updateToggleVisibility(dataTypeSelect.value, togglesContainer); // Adjust visibility on page load

    dataTypeSelect.addEventListener('change', function () {
      updateToggleVisibility(this.value, togglesContainer); // Adjust visibility on selection change
    });
  }

  // Initialize chart fetch/update when Optimal Charts tab is first shown
  var optimalChartsTabButton = document.querySelector('#optimal-charts-tab');

  if (optimalChartsTabButton) {
    optimalChartsTabButton.addEventListener('shown.bs.tab', () => {
      checkOptimalChartDataAvailability(clientId, sessionKey, retirementAge);
    });
  }

  // Get references to the toggle elements and their container for the optimal chart
  var optimalDataTypeSelect = document.getElementById('optimalDataTypeSelect');

  // Listen for changes on the optimalDataTypeSelect dropdown for the optimal chart
  if (optimalDataTypeSelect) {
    optimalDataTypeSelect.addEventListener('change', function () {
      checkOptimalChartDataAvailability(clientId, sessionKey, retirementAge); // Fetch data on selection change
    });
  }

  // Set default value to one of the allowed data types if not already set
  if (!['asset_paths', 'net_paths', 'replacement_ratios', 'replacement_ratio_distribution', 'depletion_risk'].includes(optimalDataTypeSelect.value)) {
    optimalDataTypeSelect.value = 'asset_paths'; // or any other allowed default
  }

  // Check for optimal chart data availability on page load
  checkOptimalChartDataAvailability(clientId, sessionKey, retirementAge);
});

// alm optimal charts
async function checkOptimalChartDataAvailability(clientId, sessionKey, retirementAge) {
  const optimalDataTypeSelect = document.getElementById('optimalDataTypeSelect');

  if (!optimalDataTypeSelect) {
    console.error('Optimal data type select element is not available.');
    return;
  }

  const dataType = optimalDataTypeSelect.value;
  const numPortfolios = 10; // Hardcoded as per your description
  const url = `/alm/get-optimal-chart-data/${dataType}/${clientId}/${sessionKey}/${retirementAge}/?num_portfolios=${numPortfolios}`;

  const allowedDataTypes = ['asset_paths', 'net_paths', 'replacement_ratios', 'replacement_ratio_distribution', 'depletion_risk'];

  if (!allowedDataTypes.includes(dataType)) {
    console.error('Invalid data type selected for optimal charts:', dataType);
    return;
  }

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.error) {
      console.error('Error fetching optimal ALM data:', data.error);
      return;
    }

    if (data.labels && data.labels.length > 0) {
      window.fullOptimalChartData = data;
      displayOptimalChartsTab();

      if (window.optimalChart) window.optimalChart.destroy();
      window.optimalChart = updateOptimalChart(retirementAge);
    } else {
      setTimeout(() => checkOptimalChartDataAvailability(clientId, sessionKey, retirementAge), 5000);
    }
  } catch (error) {
    console.error('Error fetching optimal chart data:', error);
  }
}

function displayOptimalChartsTab() {
  const optimalChartsTabButton = document.getElementById('optimal-charts-tab');
  const optimalChartsTabContent = document.getElementById('optimal-charts-tab-content');

  if (optimalChartsTabButton) {
    optimalChartsTabButton.classList.remove('d-none');
    optimalChartsTabButton.classList.add('show');
  }

  if (optimalChartsTabContent) {
    optimalChartsTabContent.classList.remove('d-none');
    optimalChartsTabContent.classList.add('show');
  }
}

function updateOptimalChart(retirementAge) {
  const optimalChart = window.optimalChart;
  const fullOptimalChartData = window.fullOptimalChartData;
  const ctx = document.getElementById('optimalAlmChart').getContext('2d');

  if (!fullOptimalChartData || !ctx) return;

  if (optimalChart) optimalChart.destroy();

  const settings = getOptimalUserSettings();
  const { dataType, isCurrency } = settings;
  const chartLabel = formatChartLabel(dataType);

  let labels, datasets;
  if (dataType === "replacement_ratio_distribution") {
    labels = fullOptimalChartData.labels.map(value => value); // Use the labels directly
    datasets = fullOptimalChartData.datasets;
  } else {
    labels = fullOptimalChartData.labels;
    datasets = fullOptimalChartData.datasets;
  }

  window.optimalChart = new Chart(ctx, configureOptimalChart(labels, datasets, isCurrency, chartLabel, retirementAge, dataType));
  return window.optimalChart;
}

function getOptimalUserSettings() {
  const dataType = document.getElementById('optimalDataTypeSelect').value;
  const isCurrency = dataType !== 'replacement_ratios' && dataType !== 'replacement_ratio_distribution' && dataType !== 'depletion_risk';
  return { dataType, isCurrency };
}

function configureOptimalChart(labels, datasets, isCurrency, chartLabel, retirementAge, dataType) {
  // Adjust retirementAge for depletion_risk
  retirementAge = dataType === 'depletion_risk' ? 90 : retirementAge;

  return {
    type: 'line',
    data: {
      labels: labels,  // Ensure labels are correctly assigned
      datasets: datasets
    },
    options: {
      scales: {
        x: {
          title: { display: true, text: dataType === "replacement_ratio_distribution" ? 'Replacement Ratio %' : 'Age' },
          ticks: {
            callback: function(value, index, values) {
              return labels[index]; // Use the label values directly
            },
            autoSkip: false // Ensure all labels are displayed
          }
        },
        y: {
          beginAtZero: true,
          title: { display: true, text: dataType === "replacement_ratio_distribution" ? 'Distribution %' : '' },
          ticks: {
            callback: value => isCurrency ? `R ${value.toLocaleString()}` : `${value}%`
          }
        }
      },
      plugins: {
        title: {
          display: true,
          text: chartLabel,
          padding: { top: 10, bottom: 30 },
          font: { size: 16 }
        },
        legend: {
          labels: { font: { size: 12 } }
        },
        datalabels: {
          display: false  // Disable datalabels completely
        }
      },
      transitions: {
        show: {
          animations: {
            x: { from: 0 },
            y: { from: 0 }
          }
        },
        hide: {
          animations: {
            x: { to: 0 },
            y: { to: 0 }
          }
        }
      }
    }
  };
}

// ALM Optimisation
let portfolios; // Declare portfolios as a global variable

async function fetchAndDisplayResults(clientId, sessionKey, taskId) {
  const response = await fetch(`/alm/get-optimization-results/${clientId}/${sessionKey}/${taskId}/`);
  if (response.ok) {
    const data = await response.json();
    if (data.status === 'success') {
      // If optimization results are available, display them on the page
      const optimizedAllocations = data.allocations_data.optimized_allocations;
      const startingAllocations = data.allocations_data.starting_allocations;
      portfolios = groupByPortfolio(optimizedAllocations);
      createDynamicPortfolioTabs(portfolios); // Dynamically create tabs
      plotOptimizedAllocations(portfolios, startingAllocations);
    } else {
      // If optimization results are not available, proceed with WebSocket communication
      initializeWebSocket(clientId, sessionKey, taskId);
    }
  } else {
    console.info('Optimisation result not available yet. Proceeding with WebSocket communication.');
    // Proceed with WebSocket communication if there's an error fetching results
    initializeWebSocket(clientId, sessionKey, taskId);
  }
}

function groupByPortfolio(optimizedAllocations) {
  // Use a Map to maintain insertion order of portfolios
  const portfolioGroups = new Map();

  optimizedAllocations.forEach(allocation => {
    const portfolioName = allocation.Portfolio;
    if (!portfolioGroups.has(portfolioName)) {
      // If the portfolio hasn't been added to the map, initialize with an empty array
      portfolioGroups.set(portfolioName, []);
    }
    // Add the current allocation to the appropriate portfolio's array
    portfolioGroups.get(portfolioName).push(allocation);
  });

  return portfolioGroups;
}

function plotOptimizedAllocations(portfolioGroups, startingAllocations) {
  portfolioGroups.forEach((allocations, portfolioName) => {
    // Convert the portfolio name into a slug format for use as an ID
    const chartContainerId = portfolioNameToSlug(portfolioName) + '-charts';

    // Find the starting allocation that matches the current portfolio name
    const matchingStartingAllocation = startingAllocations.find(a => a.life_stage_name === portfolioName);
    if (!matchingStartingAllocation) {
      console.error(`No starting allocation found for portfolio: ${portfolioName}`);
      return; // Skip plotting for this portfolio as we don't have starting allocations
    }
    // Now we pass the found starting allocation for the portfolio to the plotting function
    plotPortfolioAllocations(portfolioName, allocations, chartContainerId, matchingStartingAllocation);
  });
}

function portfolioNameToSlug(portfolioName) {
  // Convert to lowercase, trim leading/trailing spaces, and replace spaces with hyphens
  return portfolioName.toLowerCase().trim().replace(/\s+/g, '-');
}

function downloadAllocationData(portfolioName) {
  const allocations = portfolios.get(portfolioName);
  if (!allocations) {
      console.error('No data found for', portfolioName);
      return;
  }

  // Dynamically generate CSV headers based on the keys of the first allocation object
  const headers = Object.keys(allocations[0]);
  let csvContent = 'data:text/csv;charset=utf-8,';
  // Add 'Portfolio' as the first column header manually, then add the rest from the allocation object
  csvContent += 'Portfolio,' + headers.join(',') + '\n';

  // Iterate over each allocation to add its values to the CSV content
  allocations.forEach((allocation) => {
      const row = [portfolioName, ...headers.map(header => allocation[header])];
      csvContent += row.join(',') + '\n';
  });

  // Encode the CSV content to create a URI
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${portfolioName}_allocations.csv`);
  document.body.appendChild(link); // Required for Firefox
  link.click();
  document.body.removeChild(link);
}

function currencyFormatter(value) {
  let formattedValue = value;
  let prefix = value < 0 ? '-' : '';
  formattedValue = Math.abs(formattedValue);

  if (formattedValue >= 1e6) {
    formattedValue = `R ${prefix}${(formattedValue / 1e6).toFixed(1)}M`;
  } else if (formattedValue >= 1e3) {
    formattedValue = `R ${prefix}${(formattedValue / 1e3).toFixed(0)}k`;
  } else {
    formattedValue = `R ${prefix}${formattedValue.toFixed(0)}`;
  }

  return formattedValue;
}

function percentageFormatter(value) {
  return `${(value * 100).toFixed(2)}%`;
}

function floatFormatter(value) {
  return value.toFixed(2);
}

function chartOptions(chartName, xAxisLabel, yAxisLabel) {
  return {
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisLabel,
        },
        ticks: {
          callback: (value) => {
            if (chartName === 'riskReturn') {
              return percentageFormatter(value);
            } else {
              return currencyFormatter(value);
            }
          },
        },
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel,
        },
        ticks: {
          callback: (value) => {
            if (chartName === 'riskReturn') {
              return percentageFormatter(value);
            } else {
              return floatFormatter(value);
            }
          },
        },
      },
    },
    plugins: {
      datalabels: {
        display: false, // This disables data labels for the chart
      },
      legend: {
        display: false, // Disables the legend
      },
    },
  };
}

function plotPortfolioAllocations(portfolioName, allocations, chartContainerId, startingAllocations) {
    // Use chartContainerId to find the specific container for this portfolio's charts
    const chartsContainer = document.getElementById(chartContainerId);
    if (!chartsContainer) return;

  // Generate a unique identifier for each chart
  const uniqueId = Date.now() + Math.random().toString(36).substring(2, 15);

  // Create a separate section for each portfolio's charts for clarity
  const portfolioSectionId = `portfolio-section-${uniqueId}`;
  const portfolioSectionHtml = `
    <div id="${portfolioSectionId}" class="portfolio-section">
      <div class="chart-container">
        <h4>Risk vs Return</h4>
        <canvas id="riskReturn-${uniqueId}"></canvas>
        <h4>Objective vs Sharpe Ratio</h4>
        <canvas id="objectiveSharpe-${uniqueId}"></canvas>
        <h4>Initial vs Optimal Allocation</h4>
        <canvas id="allocationComparison-${uniqueId}"></canvas>
      </div>
    </div>
  `;
  chartsContainer.insertAdjacentHTML('beforeend', portfolioSectionHtml);

  // Define datasets with custom styling for the first allocation
  const datasetsRiskReturn = allocations.map((a, index) => ({
    label: `${portfolioName} - Allocation ${index + 1}`,
    data: [{x: a['Annualised Risk'], y: a['Annualised Return']}],
    backgroundColor: index === 0 ? 'green' : 'rgba(255, 99, 132, 1)',
    pointStyle: index === 0 ? 'rectRot' : 'circle',
    pointRadius: index === 0 ? 7 : 3, // Make the star larger
    borderColor: index === 0 ? 'green' : 'rgba(255, 99, 132, 1)',
  }));

  // Define datasets for Objective vs Sharpe Ratio with similar custom styling
  const datasetsObjectiveSharpe = allocations.map((a, index) => ({
    label: `${portfolioName} - Allocation ${index + 1}`,
    data: [{x: a['Objective'], y: a['Sharpe Ratio']}],
    backgroundColor: index === 0 ? 'green' : 'rgba(54, 162, 235, 1)',
    pointStyle: index === 0 ? 'rectRot' : 'circle',
    pointRadius: index === 0 ? 7 : 3, // Make the star larger
    borderColor: index === 0 ? 'green' : 'rgba(54, 162, 235, 1)',
  }));

  // Create the charts with the datasets
  new Chart(document.getElementById(`riskReturn-${uniqueId}`).getContext('2d'), {
    type: 'scatter',
    data: {datasets: datasetsRiskReturn},
    options: chartOptions('riskReturn', 'Annualised Volatility (Risk)', 'Annualised Return'),
  });

  new Chart(document.getElementById(`objectiveSharpe-${uniqueId}`).getContext('2d'), {
    type: 'scatter',
    data: {datasets: datasetsObjectiveSharpe},
    options: chartOptions('objectiveSharpe', 'Objective Shortfall (Higher is better)', 'Sharpe Ratio (Higher is better)'),
  });

  // Insert download button for Risk vs Return chart directly after the canvas
  const riskReturnCanvas = document.getElementById(`riskReturn-${uniqueId}`);
  const riskReturnDownloadButton = document.createElement('button');
  riskReturnDownloadButton.textContent = 'Download Risk vs Return Chart';
  riskReturnDownloadButton.classList.add('btn', 'btn-secondary', 'btn-sm', 'mt-2');
  riskReturnDownloadButton.onclick = function () {
    downloadChart(`riskReturn-${uniqueId}`);
  };
  riskReturnCanvas.insertAdjacentElement('afterend', riskReturnDownloadButton);

  // Insert download button for Objective vs Sharpe Ratio chart directly after the canvas
  const objectiveSharpeCanvas = document.getElementById(`objectiveSharpe-${uniqueId}`);
  const objectiveSharpeDownloadButton = document.createElement('button');
  objectiveSharpeDownloadButton.textContent = 'Download Objective vs Sharpe Ratio Chart';
  objectiveSharpeDownloadButton.classList.add('btn', 'btn-secondary', 'btn-sm', 'mt-2');
  objectiveSharpeDownloadButton.onclick = function () {
    downloadChart(`objectiveSharpe-${uniqueId}`);
  };
  objectiveSharpeCanvas.insertAdjacentElement('afterend', objectiveSharpeDownloadButton);

  const dataDownloadButton = document.createElement('button');
  dataDownloadButton.textContent = 'Download Data for ' + portfolioName;
  dataDownloadButton.classList.add('btn', 'btn-primary', 'mt-3');
  dataDownloadButton.onclick = () => downloadAllocationData(portfolioName);

  // Append the download button to the chartsContainer or a designated area within the portfolio tab
  chartsContainer.appendChild(dataDownloadButton);

  // Create a chart for initial vs optimal allocation comparison
  const optimalAllocation = allocations[0]; // Assuming the first allocation is the optimal one
  const assetClasses = Object.keys(startingAllocations).filter(key => key !== 'life_stage_name');
  const startingAllocationData = assetClasses.map(assetClass => Math.round(startingAllocations[assetClass] * 100));
  const optimalAllocationData = assetClasses.map(assetClass => Math.round(optimalAllocation[assetClass] * 100));

  new Chart(document.getElementById(`allocationComparison-${uniqueId}`).getContext('2d'), {
    type: 'bar',
    data: {
      labels: assetClasses,
      datasets: [
        {
          label: 'Current Asset Allocation',
          data: startingAllocationData,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
        {
          label: 'Optimal (Utility) Asset Allocation',
          data: optimalAllocationData,
          backgroundColor: 'rgba(255, 99, 132, 0.6)',
        },
      ],
    },
    options: {
      scales: {
        y: {
          title: {
            display: true,
            text: 'Allocation (%)',
          },
          beginAtZero: true,
          ticks: {
            callback: value => `${value}%`,
        },
      },
    },
      plugins: {
        title: {
          display: true,
          text: `${portfolioName} - Current vs Optimal Asset Allocation`,
          font: { size: 16 },
        },
      },
    },
  });

  // Insert download button for Allocation Comparison chart directly after the canvas
  const allocationComparisonCanvas = document.getElementById(`allocationComparison-${uniqueId}`);
  const allocationComparisonDownloadButton = document.createElement('button');
  allocationComparisonDownloadButton.textContent = 'Download Allocation Comparison Chart';
  allocationComparisonDownloadButton.classList.add('btn', 'btn-secondary', 'btn-sm', 'mt-2');
  allocationComparisonDownloadButton.onclick = function () {
    downloadChart(`allocationComparison-${uniqueId}`);
  };
  allocationComparisonCanvas.insertAdjacentElement('afterend', allocationComparisonDownloadButton);
}

function createDynamicPortfolioTabs(portfolioGroups) {
  const resultsTab = document.getElementById('resultsTab');
  const resultsTabContent = document.getElementById('resultsTabContent');

  portfolioGroups.forEach((_, portfolioName) => {
      const slugifiedName = portfolioNameToSlug(portfolioName); // Slugify the portfolio name

      // Create Tab
      const tab = document.createElement('li');
      tab.className = 'nav-item';
      const tabLink = document.createElement('button');
      tabLink.className = 'nav-link';
      tabLink.id = `${slugifiedName}-tab`;
      tabLink.dataset.bsToggle = 'tab';
      tabLink.dataset.bsTarget = `#${slugifiedName}`;
      tabLink.type = 'button';
      tabLink.role = 'tab';
      tabLink.ariaControls = slugifiedName;
      tabLink.textContent = portfolioName; // Display the original portfolio name here
      tab.appendChild(tabLink);
      resultsTab.appendChild(tab);

      // Create Tab Content
      const tabContent = document.createElement('div');
      tabContent.className = 'tab-pane fade';
      tabContent.id = slugifiedName;
      tabContent.role = 'tabpanel';
      tabContent.ariaLabelledby = `${slugifiedName}-tab`;
      tabContent.innerHTML = `<h3>${portfolioName}</h3><div id="${slugifiedName}-charts"></div>`; // Use the slugified name for consistency
      resultsTabContent.appendChild(tabContent);
  });
}

function initializeWebSocket(clientId, sessionKey, taskId) {
  // Create a new WebSocket for task updates
  const ws_scheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
  // Determine the appropriate WebSocket port based on the environment
  let ws_port = '';
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    // Use port 8000 for development environment
    ws_port = ':8000';
  }
  const ws_path = `${ws_scheme}://${window.location.hostname}${ws_port}/ws/optimization_progress_updates/${sessionKey}/`;
  const ws = new WebSocket(ws_path);

  // Function to update the optimization progress bar
  ws.onmessage = function (e) {
    const data = JSON.parse(e.data);

    if (data.status === 'success') {
      // Update the text to indicate optimization has been completed successfully
      document.getElementById('optimizationProgressText').innerText = 'Optimization completed successfully.';
      // Fetch and display results
      fetchAndDisplayResults(clientId, sessionKey, taskId);
      // Optionally, adjust the progress bar to reflect completion
      document.getElementById('optimizationProgressBar').style.width = '100%';
      document.getElementById('optimizationProgressBar').setAttribute('aria-valuenow', 100);

      // Periodically check for optimal chart data
      setTimeout(() => checkOptimalChartDataAvailability(clientId, sessionKey, data.retirement_age), 100000);
    } else if (data.status === 'no_solution') {
      // Directly update the text and ensure no further updates
      document.getElementById('optimizationProgressText').innerText = 'No viable solution was found.';
      return; // Important to return here to skip updating progress info
    } else if (data.status === 'error') {
      console.error('An error occurred during Optimisation.');
      document.getElementById('optimizationProgressText').innerText =
        'An error occurred during Optimisation.';
    } else {
      // Calculate the progress percentage
      const progressPercentage =
        (data.current_generation / data.total_generations) * 100;
      // Calculate the estimated time left
      const progressText = `Optimising ${data.current_portfolio} -> Portfolio ${data.current_portfolio_index + 1} of ${data.total_portfolios} | Evaluations: ${data.current_generation} | Estimated time left: ${data.estimated_time_left}.`;

      // Update the progress bar and text
      document.getElementById('optimizationProgressBar').style.width =
        progressPercentage + '%';
      document
        .getElementById('optimizationProgressBar')
        .setAttribute('aria-valuenow', progressPercentage);
      document.getElementById('optimizationProgressText').innerText =
        progressText;
    }
  };

  // Function to update the optimization progress bar
  ws.onclose = function (e) {
    console.error('Optimisation WebSocket closed:', e);
    document.getElementById('optimizationProgressText').innerText =
      'Progress updates stopped.';
  };

  // Function to update the optimization progress bar
  ws.onerror = function (e) {
    console.error('Optimisation WebSocket error:', e);
    document.getElementById('optimizationProgressText').innerText =
      'Optimisation error.';
  };

  // Function to update the optimization progress bar
  ws.onopen = function () {
    console.log('Optimisation WebSocket connection successfully established');
    document.getElementById('optimizationProgressText').innerText =
      'Optimising portfolios...';
  };
}

// Optimization Progress Tab
document.addEventListener('DOMContentLoaded', function () {
  var resultsPageContent = document.getElementById('resultsTabContent');
  if (!resultsPageContent) {
    // If 'resultsTabContent' does not exist, exit the script early
    return;
  }

  var sessionKey = document
    .getElementById('resultsTabContent')
    .getAttribute('data-session-key');
  var clientId = document.getElementById('resultsTabContent').getAttribute('data-client-id');
  var taskId = document.getElementById('resultsTabContent').getAttribute('data-task-id');

  // Call the existing fetchAndDisplayResults function
  fetchAndDisplayResults(clientId, sessionKey, taskId);
});

/* alm/options.html */
document.addEventListener('DOMContentLoaded', function() {
  var adjustReturnsSwitch = document.querySelector('.adjust-returns-switch');
  var assetClassViewsDiv = document.getElementById('asset-class-views');

  if (adjustReturnsSwitch && assetClassViewsDiv) {
    function toggleAssetClassViews() {
      if (adjustReturnsSwitch.checked) {
        assetClassViewsDiv.style.display = 'block';
      } else {
        assetClassViewsDiv.style.display = 'none';
      }
    }

    adjustReturnsSwitch.addEventListener('change', toggleAssetClassViews);
    toggleAssetClassViews(); // Initial toggle based on switch state
  }
});

/* clients/client_create.html */
document.addEventListener('DOMContentLoaded', function () {
  const clientCreateForm = document.getElementById('client-create-form');
  const clientEditForm = document.getElementById('client-edit-form');
  if (!clientCreateForm) return;

    var showMembershipFormCheckbox = document.getElementById('show-membership-form');
    var membershipFormContainer = document.getElementById('membership-form-container');

    showMembershipFormCheckbox.addEventListener('change', function () {
        if (this.checked) {
            membershipFormContainer.style.display = 'block';
        } else {
            membershipFormContainer.style.display = 'none';
        }
    });

  if (!clientCreateForm && !clientEditForm) return;
    // Initialize all tooltips on the page
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl, {
          container: 'body'
      });
  });
});
